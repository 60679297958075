const worksArray = [
  {
    title: "The Haven Kira",
    subTitle: "Terraced Retreats",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730019/main_1_xridyu.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024595/the_haven_q6rkqt.webp",
    region: "East Africa",
    location: "Uganda, Kira",

    description: [
      "The Haven is a contemporary residential retreat situated in Kira, a half an hour's drive from the capital city of Uganda, Kampala. The Haven is built on a sloping terrain, with great views down facing on site measuring approximately 45 decimals.",
      "Hamlet was commissioned to design an intimate residential retreat for a short stay experience, literally mimicking what space would you want to live in when you have travelled for short stays which isn't a hotel or typical Airbnb. After a careful study of the site, Hamlet proposed simple terraced retreats where all apartments' units would have gardens in the skies.",
      "Commissioned in 2023, The Haven is conceived as a natural retreat within the city suburb, with a simple concept of a retreat within the city thus the choice of materials were predominantly materials left in the natural setting to give one an experience of being say in a safari yet in town. This can be evidenced in the bare exposed natural brick,",
    ],
    subDescription:
      "Designed as an intimate residential retreat for a short stay experience in Kampala, Uganda",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730025/01_1_uvruyj.png",
        description: "Tracced Retreats posing for a landscape photo",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730021/02_1_pbxzop.webp",
        description: "An interplay of form and function",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730019/03_1_njmo3y.webp",
        description: "Birds Eye view of the Haven Kira",
        hasVideo: false,
        videoPath: "",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Dawn of Mutanoga",
    subTitle: "Family Residence",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730249/main_1_qlpqgd.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024595/Dawn_of_Mutanoga_jloyiu.webp",
    region: "East Africa",
    location: "Uganda, West, Rushere",
    description: [
      "In the words of one of the great designers of this house: Designing a home for a family is like painting a portrait. A portraits success lies not only in the artists ability to express themselves but rather in their ability to capture the expressions, character, personality, or even the soul of those being portrayed. As an architectural portrait, the home is about creating a framework for interests and needs, wishes and dreams, requirements and criteria - in short - the life the family wants to live.",
      "In the Dawn of Mutanoga, It was a family plagued by the memories of suffering of a loved one, a relative life's leading to her final days on earth. When she succumbed, the family members in the house felt inhibited by these memories.",
      "When Hamlet was commissioned for a remodel and refurbishment of the house, our response was to create a new, a fresh start, thus the whole inspiration behind the house, was new, an analogy which draws to the first appearance of light in the day, the Dawn",
    ],
    subDescription: "A fresh start, a new beginningg, an appearance of light.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730249/02_1_s6mkrj.webp",
        description:
          "A Dawn washing the facade of the house bringing in a fresh start",
        hasVideo: false,
        videoPath: "path/to/mutanoga-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730251/main_2_ggrxg2.webp",
        description:
          "Form generation with careful thought of the intersection of form, function aesthetics",
        hasVideo: false,
        videoPath: "path/to/mutanoga-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730252/main_2_vi5mre.png",
        description: "The front facade of the Dawn",
        hasVideo: false,
        videoPath: "path/to/mutanoga-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "Suburbia Akright",
    subTitle: "Family Villas",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730611/main_2_efna0m.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024594/Suburbia_klt0jw.webp",
    region: "East Africa",
    location: "Uganda, Akright Bwebajja",
    description: [
      "Suburbia: the way of life of people who live in the outer parts of a town, Cambridge.",
      "Located on the slopping terrain of Akright hill with views of the first ever expressway linking the airport to the capital - Suburbia harvests the efficiencies of combining suburban life- style into four triangular shaped family row houses.",
      "Organized like an undulating seesaw with each family house on two levels, opening up and radiating towards the down hilly land- scape, Hamlet's design for family residences retains the autonomy and individual identity for each of the four houses while creating ide- al conditions for collaboration and suburban living to flourish - an incubator for harmonious living albeit the privacy retained for each in- dividual family.",
      "Suburbia is Hamlet's contribution to Akright's masterplan of organized living, a residential community 10 minutes from Uganda's main International Airport, Entebbe. Suburbia brings four families into the neighborhood, and community, mobilizing modular con- struction with modest materials to create generous living spaces in close connection to nature and city.",
      "Four homes abstracted from our triangular tradtional architecture, hut like' are joined together to form a residential community ",
      "When people are usually starting families, they are usually subjected to the same housing type apartments' even when their needs are not suited for the apartment setup. Suburbia strategic location makes it ideal for families searching for accommodation near town in search of homely experience and a suburban lifestyle.",
      "Our goal is to pioneer a new suburban lifestyle for families At Suburbia, residents have access to a suburban standard of quality of life, yet they are still in the heart of nature, hills and Valleys, etched in Uganda tropical climate.Residents get the best of both worlds, the nature which eludes the city, and city lifestyle.",
    ],
    subDescription:
      "A midst nature's tender embrace, a suburb blooms. A Residential community with commuting distance of a city!",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730613/05_1_avt34c.webp",
        description: "A view from the parking",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730611/main_2_efna0m.webp",
        description: "Rear View",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730611/03_1_1_yps3kr.webp",
        description: "Front View",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730611/main_2_efna0m.webp",
        description: "The Suburbia bathroom interior",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730613/05_1_avt34c.webp",
        description: "The view which opens up the vast foreground hills",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730611/01_1_jdh1ak.webp",
        description: "The Ensuite master bedroom atop each house",
        hasVideo: false,
        videoPath: "",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Lubowa Villas",
    subTitle: "The Peak of Living",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731019/01_1_dkmkkn.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024597/Lubowa_Villas_dvabj7.webp",
    region: "East Africa",
    location: "Uganda, Lubowa",
    description: [
      "Welcome to the breathtaking Villas of Lubowa, where luxury living meets stunning natural beauty, Perched atop a raised terain overlooking the tranquil waters of Lake Victoria, these exclusive residences offer an unparalleled fusion of elegance, comfort, and panoramic views. Designed with meticulous attention to detail, each villa boasts a modern aesthetic that seamlessly integrates with the surrounding landscape. Expansive floor-to-ceiling windows frame the picturesque vistas, inviting the serenity of the lake and the lush greenery into every living space.",
      "The spacious interiors are bathed in natural light, creating an atmosphere of warmth and openness. High ceilings and open-plan layouts enhance the sense of airiness, while premium finishes and fixtures add a touch of sophistication to every room.",
      "Step outside onto your private terrace and immerse yourself in the beauty of the natural surroundings. Whether you're enjoying a leisurely breakfast overlooking the shimmering waters of Lake Victoria or hosting an alfresco dinner under the stars, the outdoor spaces are designed to elevate every moment.",
      "For those seeking relaxation and rejuvenation, the villas offer an array of amenities, landscaped gardens, and tranquil outdoor lounges. Tthe most captivating feature of these villas is the sense of serenity and seclusion they offer. Tucked away from the hustle and bustle of the city, yet just a short drive from the vibrant cultural attractions of Kampala, they provide the perfect retreat for those seeking luxury living in harmony with nature.",
    ],
    subDescription:
      "Welcome home to the Villas of Lubowa, where every day is a celebration of the beauty that surrounds us. Perched atop a raised terain in Lubowa, Uganda overlooking the tranquil waters of Lake Victoria.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/main_1_xf28jm.webp",
        description:
          " Tucked away from the hustle and bustle of the city yet just a short drive from the vibrant cultural attractions of Kampala the Lubowa Villas provide the perfect retreat for those seeking luxury living in harmony with nature",
        hasVideo: false,
        videoPath: "path/to/lubowa-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/02_1_rf6sy9.webp",
        description:
          "Perched atop a raised terain overlooking the tranquil waters of Lake Victoria, these exclusive residences offer an unparalleled fusion of elegance, comfort, and panoramic views",
        hasVideo: false,
        videoPath: "path/to/lubowa-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/03_1_nib2l2.webp",
        description:
          "Step outside onto your private terrace and immerse yourself in the beauty of the natural surroundings. Whether you're enjoying a leisurely breakfast overlooking the shimmering waters of Lake Victoria or hosting an alfresco dinner under the stars, the outdoor spaces are designed to",
        hasVideo: false,
        videoPath: "path/to/lubowa-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  // {
  //   title: "The Golden Courts. DRC",
  //   subTitle: "Works 08",
  //   imagePath:
  //     "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731284/main_1_vjk7bn.webp",
  //   logoPath: "/logo.webp",
  //   region: "East Africa",
  //   location: "DRC Congo, Haut Uele Province, Isiro",
  //   description: [
  //     "We were entrusted with the renovation of two colonial houses into accomodation for miners in Isiro, DRC Congo, We embarked on a journey to blend the two houses into one, merging history with modernity, respecting the architectural legacy while infusing new life into these structures.",
  //     "Embracing the colonial charm of the buildings, we envision preserving their elegant facades, adorned with intricate detailing and characteristic verandas, serving as a nod to the past. However, within these historical shells, lies the opportunity to reimagine the interiors for contemporary living, marrying comfort with cultural authenticity.",
  //     "Utilizing locally sourced materials and craftsmanship, the interiors will showcase the rich cultural heritage of the region, with hand-carved wooden accents, vibrant Congolese textiles, and indigenous artwork adorning the walls. To ensure sustainability and resilience, the renovation will integrate passive design strategies, harnessing the region's abundant sunlight and natural breezes for temperature regulation. Additionally, eco- friendly technologies such as rainwater harvesting and solar energy were incorporated to minimize environmental impact and enhance self-sufficiency.",
  //     "Outdoor spaces were transformed into lush gardens and cozy courtyards, providing residents with private retreats to connect with nature and unwind amidst the urban bustle.In essence, the renovation of these colonial houses into cottages is more than just a restoration project- it's a celebration of history, culture, and community, breathing new life into these architectural treasures and ensuring their legacy for generations to come",
  //   ],
  //   subDescription:
  //     "We were entrusted with the renovation of two colonial houses into accomodation for miners in Isiro, DRC Congo,. We embarked on a journey to blend the two houses into one, merging history with modernity, respecting the architectural legacy while infusing new life into these structures.",
  //   otherWorks: [
  //     {
  //       imagePath:
  //         "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731285/03_1_gnnsth.webp",
  //       description:
  //         "The semi-detached rooms were designed as havens of tranquility, characterized by open and airy spaces thar invite natural light ana ventilation",
  //       hasVideo: false,
  //       videoPath: "",
  //     },
  //     {
  //       imagePath:
  //         "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731285/03_1_gnnsth.webp",
  //       description:
  //         "Utilizing locally sourced materials and craftsmanship the exteriors will showcase the rich cultural heritage of the region with hand-carved wooden accents in Congolese craftmanship",
  //       hasVideo: false,
  //       videoPath: "",
  //     },
  //     {
  //       imagePath:
  //         "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731284/main_1_vjk7bn.webp",
  //       description:
  //         "Outdoor spaces were transformed into lush gardens and cozy courtyards, providing residents with private retreats to connect with nature and unwind amidst the urban bustle",
  //       hasVideo: false,
  //       videoPath: "",
  //     },
  //     // Add more otherWorks objects as needed
  //   ],
  // },
  {
    title: "The Bachelor's Pad",
    subTitle: "Private Residence",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731466/03_1_d8dp6t.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721025857/9D-removebg-preview_jcatnw.webp",
    region: "East Africa",
    location: "Uganda, Central, Kampala, Bunga",
    description: [
      "Welcometothebachelor'sresidence, where moderndesign meets functionality and style. Nestled in the heart of the city, this sleek and sophisticated abode is tailored for the urban dweller who values both aesthetics and practicality.",
      "Upon entering, guests are greeted by an open- concept living space characterized by clean lines, minimalist furnishings, and an abundance of natural light pouring in through expansive windows. The living area seamlessly flows into a state-of-the-art kitchen, equipped with top-of-the-line appliances and ample counter space for culinary endeavors or casual dining.",
      "The bedroom exudes tranquility with its subdued color palette, plush bedding, and bespoke furniture pieces designed for relaxation and rejuvenation. A spacious closet provides ample storage for the modern gentleman's wardrobe, while asleeken-suite bathroom offersaluxurious retreat with its premium fixtures and spa-like amenities.",
      "For the bachelor who loves to entertain, a private terrace beckons with panoramic views of the city skyline, perfect for hosting intimate gatherings or unwinding after a long day. And for those who seek solitude, a cozy reading nook tucked away in a quiet corner offers the ideal sanctuary for contemplation and reflection.",
      "With its seamless blend of formand function, the bachelor's residence is a testament to contemporary living at its finest",
    ],
    subDescription:
      "With its seamless blend of form and function, the bachelor's residence is a testament to contemporary living at its finest-a haven where style, comfort, and convenienceconvergetocreatethe ultimate urban oasis",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731465/01_1_cju4wu.png",
        description:
          "Nestled in the heart of the city this sleek and sophisticated abode is tailored for the urban dweller who values both aesthetics and practicality",
        hasVideo: false,
        videoPath: "path/to/bachelors-pad-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731466/02_2_vqe8lt.webp",
        description:
          "With its seamless blend of form and function, the bachelor's residence is a testament to contemporary living at its finest",
        hasVideo: false,
        videoPath: "path/to/bachelors-pad-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731466/01_1_oh8jl1.webp",
        description:
          "For the bachelor who loves to entertain, a private terrace beckons with panoramic views of the city skyline,",
        hasVideo: false,
        videoPath: "path/to/bachelors-pad-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "Veiled Vista",
    subTitle: "The View Of Living",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731728/main_2_ha8klm.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024599/Veiled_Vista_kixmys.webp",
    region: "East Africa",
    location: "Uganda, Central, Kampala, Muyenga",
    description: [
      "Veiled: Veiled Vista caps the tip of Urban Living. Privacy amidst the numbers.",
      "Veiled vista grows out of this 'Privacy in public plan' versatile framework for a lively community overlooking Lake Victoria, the world's second largest water body. Situated within 10 minutes' drive from the capital city of Uganda, the residential development has unobstructed views of Lake Victoria. Each unit is privately owned, rented and some are rented out as vacation homes.",
      "Veiled Vista brings a mix of scale and program to the new neighborhood, creating a sense of community among the local residents with an inviting vista of the Lake Victoria.",
    ],
    subDescription:
      "With Vistas vast and skies that spil, a view that forever thrills",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731727/01_1_eu28xf.webp",
        description: "A side view",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731728/02_1_w1g4od.webp",
        description: "Front view",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731728/main_3_ov4dh3.webp",
        description: "Entrace view",
        hasVideo: false,
        videoPath: "",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Courtyard House",
    subTitle: "Farmily Artpiece",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732276/main_1_exzdwc.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024596/Untitled-1_2x_ehhz8c.webp",
    region: "East Africa",
    location: "Uganda, Munyonyo",
    description: [
      "Designing a home for a family is like painting a portrait. A portrait's success lies not only in the artists' ability to express themselves but rather in their ability to capture the expressions, character, personality, or even the soul of those being portrayed. As an architectural portrait, the home is about creating a framework for interests and needs, wishes and dreams, requirements and criteria in short - the life the family wants to live.",
      "In the Courtyard house, located in Munyonyo, a 15 minutes' drive from Uganda's capital city, Kampala, the client's passion for a tightly knitted family plays a major role in the design, instead of the house outward looking, all her spaces were focused in an internal central courtyard which fostered intimate inward communion withing the family spaces thus all spaces hard an midcentury re-enaction of 'Eichler' homes.",
      "Hamlet experienced painting the expressions of a one suburban family into their family home. Architecture on such a scale of a home was a great art",
    ],
    subDescription:
      "Designing a home for a family is like painting a portrait. A portrait's success lies not only in the artists ability to express themselves but rather in their ability to capture the expressions, character, personality, or even the soul of those being portrayed.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732282/01_1_znlkwq.webp",
        description: "The Courtyard House Entrace",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732275/02_1_k2idrx.webp",
        description: "The view from the parking lot to appreciate the entrace",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732281/03_2_r9vve4.webp",
        description: "The backyard of the courtyard house",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Courtyard Choreography",
    subTitle: "The Symphony of Space and Light",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732282/01_1_znlkwq.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024596/Untitled-1_2x_ehhz8c.webp",
    region: "East Africa",
    location: "Uganda, Munyonyo",
    description: [
      " Like a maestro orchestrating a symphony, the courtyard house orchestrates an intricate dialogue between interior and exterior realms.",
      "Picture this: a central void, a sanctum within the dwelling, where walls become canvases for the play of shadows and light. The courtyard is not merely an enclosed space but an open-air theater, inviting the elements to perform a timeless ballet. Sunlight pirouettes across the floor, casting a ballet of shadows that waltz through the day.",
      "Walls, rather than barriers, become storytellers, unveiling a narrative of privacy and connection. The courtyard house is an architect's sonnet to intimacy, where rooms look inward, creating a shared nucleus for family life. Every window frames a living canvas,",
    ],
    subDescription:
      "In the rhythmic dance of architecture, the courtyard house emerges as a choreography of space, a testament to the interplay between nature and built form.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732281/04_1_tdurwe.webp",
        description:
          "The Courtyard where tears may freely fall, Nature's embrace, a comforting spawl",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732275/05_1_hqzgxz.webp",
        description: "A Courtyard view from the kitchen",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732276/03_1_iqhb5a.webp",
        description: "The backyard view",
        hasVideo: false,
        videoPath: "path/to/courtyard-house-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Bunga Heights",
    subTitle: "Condominium Homes",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732634/02_1_rs9qkn.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024597/the_bunga_heights_xrqhnf.webp",
    region: "East Africa",
    location: "Uganda, Bunga",
    description: [
      "Designed with urban elegance in mind, this vi architectural piece offers a harmonious blend se of luxury residences and penthouse living, all within reach of the bustling city of Kampala.",
      "Rising majestically above the skyline, the ap condominium block stands as a beacon of ex modernity, with sleek lines and innovative lu design elements that capture the imagination. pi From the moment you step into the lobby, st you are greeted by a sense of grandeur and yo refinement, with soaring ceilings, elegant liv furnishings, and curated artwork that exude m understated luxury. The residences within the u condominium block are thoughtfully designed to cater to the needs of contemporary urban O dwellers. Open-concept layouts maximize an space and natural light, while high-end finishes F and fixtures add a touch of opulence to every sp room Floor-to-ceiling windows offer sweeping views of the surrounding landscape, creating a seamless connection between indoors and out.",
      "But it's the penthouses atop the condominium block that truly set this development apart. Perched on the highest floors, these exclusive residences offer the ultimate in luxury living, with expansive floor plans, private terraces, and panoramic vistas that stretch as far as the eye can see. Whether you're entertaining guests in the spacious living areas or unwinding in the sumptuous master suite, every moment is infused with an unparalleled sense of sophistication and style.",
      "Outside, residents can enjoy a host of amenities designed to enhance their lifestyle. From a state-of-the-art fitness center and sparkling swimming pool to lush green spaces. there's something for everyone to enjoy.",
    ],
    subDescription:
      "Designed with urban elegance in mind, this architectural piece offers a harmonious blend of luxury residences and penthouse living, all within reach of the bustling city of Kampala.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732635/01_1_pxlwos.webp",
        description:
          "Residents can enjoy a host of amenities designed to enhance their festyle, From a state-of-the-art fitness center and sparkling swimming pool.",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732634/02_1_rs9qkn.webp",
        description:
          "Designed with urban elegance in mind, this architectural piece offers a harmonious blend of luxury residences and penthouse living, all within reach of the bustling city of Kampala",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732634/03_1_cep0cc.webp",
        description:
          "The penthouses atop the condominium block truly set this development opart. Perched on the highest floors, these exclusive residences offer the ultimate in luxury living",
        hasVideo: false,
        videoPath: "",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Buziga Crest",
    subTitle: "The Pick Of Living",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731466/02_2_vqe8lt.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024597/the_buziga_crest_ulxefm.webp",
    region: "East Africa",
    location: "Uganda, Central, Kampala, Buziga",
    description: [
      "Envision a contemporary residential block in the picturesque Buziga, Uganda, a testament to innovative design and a harmonious blend of functionality and aesthetics, as envisioned by Hamlet. This condominium block, meticulously crafted, stands as a symbol of modern living while embracing the natural beauty of its surroundings.",
      "The exterior of the residential block is a study in sleek and elegant design. A seamless interplay of tropics inspired finishes, with the locally sourced stone creates a facade that is both timeless and cutting- edge. The architecture is characterized by clean lines and a sense of transparency, allowing the building to integrate seamlessly into the landscape. The use of locally sourced materials adds a touch of regional authenticity, bridging the gap between modernity and the cultural richness of Uganda. ",
      "The residential units within the block are designed with a focus on maximizing natural light and ventilation. Floor-to-ceiling windows frame panoramic views of the lush Buziga landscape and background. The interior spaces are an embodiment of elegance, featuring a minimalist aesthetic that allows residents to personalize their living spaces according to their preferences.",
      "The jewel of this residential block is the top floor, exclusively reserved for the owner. This penthouse is a sanctuary of luxury, offering a private retreat with expansive living areas, a state-of-the-art kitchen, and a master suite that rivals the most opulent of accommodations. The design seamlessly incorporates outdoor spaces, such as terraces and balconies, providing the owner with unparalleled views of the surrounding beauty.",
    ],
    subDescription:
      "The residential units within the block are designed with a focus on maximizing natural light and ventilation. Floor-to-ceiling windows frame panoramic views of the lush Buziga landscape and background",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719730019/03_1_njmo3y.webp",
        description:
          "Hamlet's Vision: Hamlet's vision for this contemporary residential block in Buziga is not just a housing complex it is a statement of modern luxury that harmonizes with its natural setting",
        hasVideo: false,
        videoPath: "path/to/buziga-crest-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731466/01_1_oh8jl1.webp",
        description:
          "Unique Owners Opportunity: It offers a unique opportunity for the owner to reside in a penthouse that represents the pinnacle of sophisticated living, all within the embrace of Uganda's scenic beauty",
        hasVideo: false,
        videoPath: "path/to/buziga-crest-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731464/02_1_wjnkvi.webp",
        description:
          "Her Location: She is tucked away on one of the hilltops in Uganda Buziga hill, which is a 15 minutes drive from the capital city of Ugando",
        hasVideo: false,
        videoPath: "path/to/buziga-crest-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Jurist's Retreat",
    subTitle: "Private Residence",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732839/01_1_tcxh6n.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024596/the_jurists_retreat_g9nkso.webp",
    region: "East Africa",
    location: "Uganda, central, Kampala, Bunga",
    description: [
      "This modern architectural marvel is tailored to embody the refined tastes and professional ethos of a discerning lawyer. Elegance meets functionality in every corner of this abode, where sleek lines and minimalist design converge to create an atmosphere of sophistication and serenity.",
      "Upon arrival, a grand entrance sets the tone, welcoming guests into a spacious foyer adorned with tasteful artwork and ambient lighting. The open-concept layout seamlessly integrates the living, dining, and kitchen areas, ideal for hosting intimate gatherings or unwinding after a long day in the courtroom. ",
      "For the legal aficionado seeking solace, a private study offers a sanctuary for contemplation and focused work, complete with custom-built shelving to house an extensive library of legal tomes. Floor-to-ceiling windows flood the space with natural light, fostering an ambiance conducive to productivity and creativity.",
      "Ascending the staircase, the upper level reveals a luxurious mastersuite, featuringasumptuousbedroomretreat, aspa- inspired ensuite bath, and a secluded balcony overlooking lush greenery-a tranquil oasis for rest and rejuvenation.",
      "Outside, expansive terraces beckon for al fresco dining and leisure, while meticulously landscaped gardens provide a verdant backdrop for outdoor gatherings or quiet reflection. A detached guest house offers additional accommodations for visiting colleagues or clients, ensuring utmost privacy and convenience.",
    ],
    subDescription:
      "The Jurist's Retreat is more than just a home, it's a testament to the harmonious fusion of form and function, tailored to meet the discerning needs of a modern legal professional seeking respite from the rigors of the legal arena.",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732837/01_2_wmianz.webp",
        description:
          "This modern architectural house is tailored to embody the refined tastes and professional ethos of a discerning lawyer Elegance meets functionality in every corner of this",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732840/02_1_vtqud2.webp",
        description:
          "Elegance meets functionality in every corner of this abode where sleek lines and minimalist design converge to create an atmosphere of sophistication and serenity.",
        hasVideo: false,
        videoPath: "",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732841/03_1_kyd7is.webp",
        description:
          "The Jurist's Retreat is more than just a home, it's a testament to the harmonious fusion of form and function, tailored to meet the discerning needs of a modern legal professional seeking respite from the gors of the legal arena",
        hasVideo: false,
        videoPath: "",
      },
      // Add more otherWorks objects as needed
    ],
  },
  {
    title: "The Mist Entebbe",
    subTitle: "Boutique Hotel",
    imagePath:
      "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732971/01_2_rw2z8e.webp",
    logoPath:
      "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024596/the_mist_entebbe_u5we6z.webp",
    region: "East Africa",
    location: "Uganda, Central, Entebbe",
    description: [
      "The Mist Hotel Entebbe is part of a chain of the Mist hotels in Uganda, She blends modern luxury with the rich cultural and natural surroundings. This boutique hotel is a testament to Uganda culture at the intersection of modernism, creating an immersive and unforgettable experience for its guests. The architecture of the hotel is inspired by the vibrant local culture, with a contemporary twist that reflects the dynamism of Entebbe.",
      "Upon entering the hotel, guests are greeted by an open and inviting lobby, flooded with natural light and adorned with unique art pieces showcasing Uganda's diverse artistic heritage. The reception area seamlessly transitions into a communal space, fostering a sense of community and connection among guests.",
      "The guest rooms are designed to provide a harmonious blend of comfort and sophistication. Floor-to-ceiling windows offer breathtaking views of the surrounding landscape, while the interior decor is a fusion of modern aesthetics and traditional Ugandan craftsmanship. Each room tells a story through its design, creating a sense of place that immerses guests in the local culture. The outdoor spaces are thoughtfully designed to encourage social interaction, with cozy seating areas and a bar serving locally inspired cocktails, and continental ones",
    ],
    subDescription:
      "This boutique hotel in Entebbe is not just a place to stay; it's a destination that celebrates the spirit of Uganda while providing a luxurious and sustainable retreat for travelers seeking an immersive and culturally rich experience",
    otherWorks: [
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732967/01_1_u4bqow.webp",
        description:
          "Dining: Dining at the hotel is a culinary journey that celebrates Uganda's diverse gastronomic heritage. The restaurant, designed with great ambiance, offers a menu that highlights fresh, locally sourced ingredients, creating a fusion of flavors that tantalize the taste buds",
        hasVideo: false,
        videoPath: "path/to/mist-entebbe-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732966/02_1_yq98mm.webp",
        description:
          "Sustainability: In true Hamlet style, sustainability is at the forefront of the hotel's design. Solar panels, rainwater harvesting systems contribute to the eco-friendly ethos, ensuring that the hotel minimizes its environmental impact..",
        hasVideo: false,
        videoPath: "path/to/mist-entebbe-video.mp4",
      },
      {
        imagePath:
          "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732965/03_1_nvzhim.webp",
        description:
          "Under Construction: This boutique hotel in Entebbe is in its final cosntruction stages and once complete shall celebrates the spirit of Uganda while providing a luxurious and sustainable retreat for travelers seeking an immersive and culturally rich environment",
        hasVideo: false,
        videoPath: "path/to/mist-entebbe-video.mp4",
      },
      // Add more otherWorks objects as needed
    ],
  },
];

const testProject = {
  title: "The Lubowa Villas",
  subTitle: "The Peak of Living",
  imagePath:
    "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719731019/01_1_dkmkkn.webp",
  logoPath:
    "https://res.cloudinary.com/dqgh8v3bn/image/upload/v1721024597/Lubowa_Villas_dvabj7.webp",
  region: "East Africa",
  location: "Uganda, Lubowa",
  description: [
    "Welcome to the breathtaking Villas of Lubowa, where luxury living meets stunning natural beauty, Perched atop a raised terain overlooking the tranquil waters of Lake Victoria, these exclusive residences offer an unparalleled fusion of elegance, comfort, and panoramic views. Designed with meticulous attention to detail, each villa boasts a modern aesthetic that seamlessly integrates with the surrounding landscape. Expansive floor-to-ceiling windows frame the picturesque vistas, inviting the serenity of the lake and the lush greenery into every living space.",
    "The spacious interiors are bathed in natural light, creating an atmosphere of warmth and openness. High ceilings and open-plan layouts enhance the sense of airiness, while premium finishes and fixtures add a touch of sophistication to every room.",
    "Step outside onto your private terrace and immerse yourself in the beauty of the natural surroundings. Whether you're enjoying a leisurely breakfast overlooking the shimmering waters of Lake Victoria or hosting an alfresco dinner under the stars, the outdoor spaces are designed to elevate every moment.",
    "For those seeking relaxation and rejuvenation, the villas offer an array of amenities, landscaped gardens, and tranquil outdoor lounges. Tthe most captivating feature of these villas is the sense of serenity and seclusion they offer. Tucked away from the hustle and bustle of the city, yet just a short drive from the vibrant cultural attractions of Kampala, they provide the perfect retreat for those seeking luxury living in harmony with nature.",
  ],
  subDescription:
    "Welcome home to the Villas of Lubowa, where every day is a celebration of the beauty that surrounds us. Perched atop a raised terain in Lubowa, Uganda overlooking the tranquil waters of Lake Victoria.",
  otherWorks: [
    {
      imagePath:
        "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/main_1_xf28jm.webp",
      description:
        " Tucked away from the hustle and bustle of the city yet just a short drive from the vibrant cultural attractions of Kampala the Lubowa Villas provide the perfect retreat for those seeking luxury living in harmony with nature",
      hasVideo: false,
      videoPath: "path/to/lubowa-video.mp4",
    },
    {
      imagePath:
        "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/02_1_rf6sy9.webp",
      description:
        "Perched atop a raised terain overlooking the tranquil waters of Lake Victoria, these exclusive residences offer an unparalleled fusion of elegance, comfort, and panoramic views",
      hasVideo: false,
      videoPath: "path/to/lubowa-video.mp4",
    },
    {
      imagePath:
        "https://res.cloudinary.com/dbxajbjc5/image/upload/v1719732083/03_1_nib2l2.webp",
      description:
        "Step outside onto your private terrace and immerse yourself in the beauty of the natural surroundings. Whether you're enjoying a leisurely breakfast overlooking the shimmering waters of Lake Victoria or hosting an alfresco dinner under the stars, the outdoor spaces are designed to",
      hasVideo: false,
      videoPath: "path/to/lubowa-video.mp4",
    },
    // Add more otherWorks objects as needed
  ],
};
export { worksArray, testProject };
