function Footer() {
  return (
   <div className="bg-white fixed-bottom">
     <div className="container-fluid ">
      <footer className="p-1">
        <ul className="nav flex-column flex-md-row  justify-content-evenly text-center text-md-start">
          <li className="nav-item mb-3 mb-md-0">
            <div className="d-flex flex-column flex-md-row align-items-center ">
              <img
                src="/images/A.webp"
                alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup Company Logo"
                className="img-fluid mb-2"
                style={{ width: "35px" }}
              />
              <span className="fw-medium text-muted lead">Let's design</span>
            </div>
          </li>
          <li className="nav-item mb-3 mb-md-0">
            <a
              href="tel:+256200940000"
              className="nav-link px-2 text-body-secondary d-flex flex-column flex-md-row align-items-center gap-2"
            >
              <i className="bi bi-telephone fs-5"></i>
              <span>+256 200 940 000</span>
            </a>
          </li>
          <li className="nav-item mb-3 mb-md-0">
            <a
              href="mailto:info@hamlet.ug"
              className="nav-link px-2 text-body-secondary d-flex flex-column flex-md-row align-items-center gap-2"
            >
              <i className="bi bi-envelope fs-5"></i>
              <span>info@hamlet.ug</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://goo.gl/maps/xyz"
              className="nav-link px-2 text-body-secondary d-flex flex-column flex-md-row align-items-center gap-2"
            >
              <i className="bi bi-geo-alt fs-5"></i>
              <span>Hamlet Hub, Muyenga, Uganda</span>
            </a>
          </li>
        </ul>
      </footer>
    </div>
   </div>
  );
}

export default Footer;
