import SmallCard from "../Components/Our-Works/SmallCard";
import DetailedCard from "../Components/Our-Works/DetailedCard";
import { worksArray } from "../constants";

import React, { useState } from "react";

import "swiper/css";

function OurPortifolio() {
  // const ref = useRef(null);
  // const { scrollYProgress } = useScroll({
  //   target: ref,
  //   offset: ["start start", "end end"],
  // });

  const [showMore, setShowMore] = useState(-1);
  // const [display, setDisplay] = useState(2);

  const handleMouseEnter = (id) => {
    setShowMore(id);
  };

  // const handleMouseLeave = () => {
  //   setShowMore(-1);
  // };

  return (
    <>
      <div className="container-fluid bg-info " style={{paddingTop: "4rem"}}>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column gap-4 bg-dange d-grid">
              {worksArray?.map((dta, k) => (
                <>
                  {showMore === k ? (
                    <DetailedCard data={dta} />
                  ) : (
                    <SmallCard
                      data={dta}
                      handleMouseEnter={() => handleMouseEnter(k)}
                      workId={k}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurPortifolio;
