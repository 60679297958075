import "./assets/scss/styles.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";

import LayOut from "./layout";
import OurPortifolio from "./pages/OurPortifolio";


function App() {
  return (
    <>
      <LayOut>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>

            <Route path="/portifolio" element={<OurPortifolio />}></Route>

          </Routes>
        </Router>
      </LayOut>
    </>
  );
}
export default App;
