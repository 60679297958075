// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
// Import Swiper styles
import "swiper/css";
import { Autoplay, Mousewheel, Parallax } from "swiper/modules";
import { useMediaQuery } from "@uidotdev/usehooks";
import OtherSmallCard from "./OtherSmallCard";

function DetailedCard({ data }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 3, delay: 0.5 }}
      className="container-fluid py-2"

      // style={{height: "98%"}}
    >
      {" "}
      <Swiper
        modules={[Autoplay, Mousewheel, Parallax]}
        grabCursor={true}
        // centeredSlides={true}
        mousewheel={true}
      
        speed={3000}
        parallax={true}
        slidesPerView={1.5}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        className="d-grid  h-100 bg-prima"
        style={{ marginLeft: isSmallDevice ? "-25%": "" }}
      >
        {isSmallDevice && (
          <SwiperSlide>
            <OtherSmallCard data={data} />
          </SwiperSlide>
        )}
        <SwiperSlide className="bg-primar" style={{ maxWidth: "4%" }}>
          <div className="d-flex">
            <div className="d-flex flex-column h-100 w-100 align-items-end p-2">
              <img
                src={data.logoPath}
                className="img-fluid mb-3 d-none d-lg-block"
                alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup"
                style={{ width: "5rem" }}
              />
              <h6
                className="card-title btn p-0 text-uppercase text-end lh-sm mb-3 d-none d-lg-block"
                style={{ letterSpacing: "1px" }}
              >
                <small>{data.title}</small>
              </h6>
              <div>
                <p className="card-title text-md-end mb-2">
                  <small className="text-uppercase text-secondary text-base fw-medium lh-sm">
                    Typology
                  </small>
                  <br />
                  <small>{data.subTitle}</small>
                </p>
                <p className="card-title text-md-end mb-2">
                  <small className="text-uppercase text-secondary text-base fw-medium lh-sm">
                    Region
                  </small>
                  <br />
                  <small>{data.region}</small>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="bg-dange">
          <div>
            {" "}
            <img
              src={data.imagePath}
              className="img-fluid "
              alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup"
              style={{ objectFit: "cover", objectPosition: "center" }}
            />
          </div>
        </SwiperSlide>
        {data.otherWorks.map((other, k) => (
          <SwiperSlide>
            <div className="container-fluid d-flex  h-100 w-100 bg-seconda">
              <div className="d-flex ">
                {" "}
                <div className=" d-none d-lg-block" style={{ maxWidth: "15%" }}>
                  {" "}
                  <div className="d-flex flex-column h-100 w-100 align-items-end text-end p-2">
                    <p>
                      <small>{other.description}</small>
                    </p>
                  </div>
                </div>
                <div>
                  {" "}
                  <img
                    src={data.imagePath}
                    className="img-fluid  "
                    alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup"
                    // style={{ objectFit: "cover", objectPosition: "center" }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
     
        ))}
      </Swiper>
    </motion.div>
  );
}

export default DetailedCard;
