function OtherSmallCard({ data }) {
  return (
    <div className="container d-flex bg-inf h-100 w-100 mb-3">
      <div className="row  bg-primar justify-content-center ">
        <div className="col-md-5 ">
          <img
            key="image"
            src={data.imagePath}
            className="img-fluid"
            alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup"
          />
        </div>
        <div className="col-md-2 ">
          <div className="card-body row text-md-end d-flex flex-column align-items-end ">
            <div className="row  bg-warnin justify-content-md-end ">
              <div className="col-md-12 col-3 mb-3 justify-md-end bg-inf">
                {" "}
                <img
                  src={data.logoPath}
                  className="img-fluid  "
                  alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup The project logo is shown here bg-secondary"
                  style={{ width: "5rem" }}
                />
              </div>
              <div className="text-md-end row justify-content-md-end bg-secon col-md-12 col-8">
                <p className="card-title lh-sm">
                  <small>{data.title}</small>
                  <br />
             
                    <small
                      className="text-body-secondary fw-lighter "
                      style={{ fontSize: "8px" }}
                    >
                      {data.location}
                    </small>
             
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherSmallCard;
