import Footer from "../Components/footer";
import NavBar from "../Components/NavBar";


function LayOut({ children }) {
  return (
    <>
  
<NavBar/>
      <section>{children}</section>
      <Footer/>
    </>
  );
}

export default LayOut;
