import { Link } from "react-router-dom";
import AnimatedSubHeading from "../Components/BannerComponents/AnimatedSubHeading";

import { motion } from "framer-motion";
import LogoSvg from "../Components/Logo/LogoSvg";
import { useMediaQuery } from "@uidotdev/usehooks";
import OurPortifolio from "./OurPortifolio";

function HomePage() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  return (
    <>
      <motion.div
        // style={{
        // backgroundImage: "url('/images/homebg.webp')",
        // backgroundPosition: "end",
        // backgroundSize: "contain",
        // backgroundRepeat: "no-repeat",
        // }}
        // style={{ backgroundColor: "#F5F4F9" }}
        className=""
      >
        {" "}
        <section
          style={{
            backgroundImage: "url('/images/homebg.webp')",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            // minHeight: "50vh"
          }}
          className="d-flex justify-content-center container-fluid  w-100 "
        >
          <div className="w-100 h-100 bg-light bg-opacity-75  smoothView ">
            {" "}
            <div
              className="containe d-grid  bg-dange min-vh-md-100"
              style={{ minHeight: isSmallDevice ? "65vh" : "100vh" }}
            >
              <div className="row bg-primar    align-items-md-start align-items-center">
                <div className="col-12 text-center">
                  <div className=" row bg-secondar justify-content-center ">
                    <div className=" col-md-8 bg-primar ">
                      <LogoSvg />
                    </div>
                  </div>
                  <div className="bg-inf py-2">
                    <AnimatedSubHeading />
                  </div>
                </div>
              </div>
              <div className="row  bg-dange ">
                <div className="col-md-12 d-flex align-items-end justify-content-center ">
                  <motion.div
                    className=" bg-inf"
                    type="button"
                    initial={{ y: [-20, 20] }}
                    animate={{ y: 0 }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                    }}
                    whileHover={{ scale: 0.8 }}
                  >
                    <Link to="/portifolio">
                      <img
                        src="/images/arrowDown.webp"
                        className="img-fluid"
                        alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup arrow"
                      />
                    </Link>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
      <OurPortifolio />
    </>
  );
}

export default HomePage;
