import { AnimatePresence, motion } from "framer-motion";

function LogoSvg() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, staggerChildren: 0.2, delayChildren: 0.5 },
    },
  };

  const letterVariants = (direction) => ({
    hidden: {
      [direction]: -50,
      scale: 0.8,
      rotate: 15,
      fill: "transparent",
      stroke: "gray",
      strokeWidth: 2,
    },
    visible: {
      [direction]: 0,
      scale: 1,
      rotate: 0,
      fill: "#686868",
      stroke: "transparent",
      strokeWidth: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  });

  return (
    <AnimatePresence mode="wait">
      <motion.svg
        viewBox="0 0 500 100"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-font d-flex justify-content-center"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        // style={{ backgroundColor: "red" }}
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: "red", stopOpacity: 1 }} />
            <stop
              offset="100%"
              style={{ stopColor: "white", stopOpacity: 0 }}
            />
          </linearGradient>
        </defs>
        <motion.g
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          transform="translate(0, 20)"
        >
          <motion.text
            variants={letterVariants("y")}
            x="50"
            y="70"
            fontSize="50"
            letterSpacing="10"
          >
            H
          </motion.text>

          <foreignObject
            x="120"
            y="30"
            width="50"
            height="50"
            letterSpacing="10"
          >
            <img
              src="/images/A.webp"
              alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup logo letter"
              className="img-fluid h-100"
            />
          </foreignObject>

          <motion.text
            variants={letterVariants("x")}
            x="190"
            y="70"
            fontSize="50"
            letterSpacing="10"
          >
            M
          </motion.text>
          <motion.text
            variants={letterVariants("y")}
            x="260"
            y="70"
            fontSize="50"
            letterSpacing="10"
          >
            L
          </motion.text>

          <foreignObject
            x="330"
            y="30"
            width="44"
            height="44"
            letterSpacing="10"
          >
            <img
              src="/images/E.webp"
              alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup logo letter"
              className="img-fluid h-100"
            />
          </foreignObject>
          <motion.text
            variants={letterVariants("y")}
            x="400"
            y="70"
            fontSize="50"
            letterSpacing="10"
          >
            T
          </motion.text>
        </motion.g>
      </motion.svg>
    </AnimatePresence>
  );
}

export default LogoSvg;
