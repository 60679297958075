import { motion } from "framer-motion";
import React from "react";

const splitText = (text) =>
  text.split("").map((char, index) => (
    <motion.span
      key={index}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.1, delay: index * 0.05 }}
    >
      {char}
    </motion.span>
  ));

const AnimatedSubHeading = () => (
  <div className="py-4 container">
    <div className="py-2">
      {" "}
      <h3 className="text-center   mb-4  opacity-75 fw-bold opacity-25   lh-lg  ">
        {splitText("World Of Extraordinary Design")}
      </h3>
      <h4 className="lea text-center  d-flex justify-content-center  ">
        <span className="col-12 col-sm-10 col-md-8 col-xxl-7 fw-light fs-6 lh-xl opacity-75 text-sm-center">
          {splitText("Let's Design Something Wonderful Together")}
        </span>
      </h4>
    </div>
    {/* <div className="  d-grid gap-2 d-sm-flex justify-content-sm-center">
      <motion.button
        type="button"
        className="btn btn-outline-danger btn-lg text-uppercase fw-light  "
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ type: "spring", stiffness: 300 }}
        aria-label="Free Consultation"
        // style={{ backgroundColor: " #EEABB6DD" }}
      >
        Free Consultation
      </motion.button>
    </div> */}
  </div>
);

export default AnimatedSubHeading;
