import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

function SmallCard({ data, handleMouseEnter, workId }) {
  const [showImage, setShowImage] = useState(false);

  // const cardVariants = {
  //   hidden: { opacity: 0, y: 20 },
  //   visible: { opacity: 1, y: 0 },
  //   exit: { opacity: 0, y: 20 },
  // };

  const imageVariants = {
    hidden: { opacity: 0.03 },
    visible: { opacity: 1 },
    exit: { opacity: 0.03 },
  };

  const handleImageError = (e) => {
    console.error(`Image not found: ${e.target.src}`);
    e.target.src = "path/to/placeholder/image.png"; // Fallback image
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={imageVariants}
        transition={{
          duration: 1,
          delay: 1,
          exit: { duration: 1, delay: 3 },
          ease: "easeInOut",
        }}
        className="container d-flex bg-inf h-100 w-100 mb-3"
      >
        <div className="row  bg-primar justify-content-center ">
          <div className="col-md-2 ">
            <div className="card-body row text-md-end d-flex flex-column align-items-end ">
              <div className="row  bg-warnin justify-content-md-end ">
                <div className="col-md-12 col-3 mb-3 justify-md-end bg-inf">
                  {" "}
                  <img
                    src={data.logoPath}
                    className="img-fluid  "
                    alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup The project logo is shown here bg-secondary"
                    onError={handleImageError}
                    style={{ width: "5rem" }}
                  />
                </div>
                <div className="text-md-end row justify-content-md-end bg-secon col-md-12 col-8">
                  <p className="card-title">
                    <span>{data.title}</span>
                    <br />
                    <span>
                      <small
                        className="text-body-secondary fw-lighter lh-sm"
                        // style={{ fontSize: "10px" }}
                      >
                        {data.location}
                      </small>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-5 "
            onMouseEnter={() => setShowImage(true)}
            onMouseLeave={() => setShowImage(false)}
            onClick={() => handleMouseEnter(workId)}
          >
            {/* <AnimatePresence mode="wait"> */}
            {showImage ? (
              <motion.img
                key="image"
                src={data.imagePath}
                className="img-fluid"
                alt="..."
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={imageVariants}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  exit: { duration: 1.5 },
                  ease: "easeInOut",
                }}
                onError={handleImageError}
              />
            ) : (
              <motion.img
                key="logo"
                src={data.logoPath}
                className="img-fluid "
                alt="..."
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={imageVariants}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  exit: { duration: 1.5 },
                  ease: "easeInOut",
                }}
                onError={handleImageError}
              />
            )}
            {/* </AnimatePresence> */}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default SmallCard;
