function NavBar() {
  return (
    <nav className="navbar navbar-light fixed-top z-3" aria-label="First navbar example">
      <div className="container-fluid p-4">
        <a className="navbar-brand" href="/">
          .
        </a>
        <button
          className="navbar-toggle text-link bg-transparent shadow-0 border-0 mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="bi bi-telephone fs-4"></i>
        </button>
        <div
          className="offcanvas offcanvas-end border-0"
          style={{ maxWidth: "15rem" }}
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header p-4 border-bottom">
           
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body p-4">
            <div className="container">
              <div className="row mb-4">
                <div className="col-3">
                  <img
                    src="/images/A.webp"
                    alt="best architects in Uganda, best architectures in Uganda, house plans in Uganda, architectural design, Hamletgroup company logo"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="col-9 d-flex align-items-center mb-4">
                  <p className="text-secondary lead mb-0 fw-bold">Let's design</p>
                </div>
              </div>
              <div className="row gy-4">
                <div className="col-12">
                  <div className="d-flex align-items-center gap-3">
                    <i className="bi bi-telephone fs-5"></i>
                    <div className="d-flex flex-column">
                      <a href="tel:+256200940000" className="text-decoration-none fw-thin btn btn-sm text-start text-muted">
                        +256 200 940 000
                      </a>
                      <a href="tel:+256772205089" className="text-decoration-none fw-thin btn btn-sm text-start text-muted">
                        +256 772 205 089
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center gap-3">
                    <i className="bi bi-envelope fs-5"></i>
                    <div className="d-flex flex-column">
                      <a href="mailto:info@hamlet.ug" className="text-decoration-none fw-thin btn btn-sm text-start text-muted">
                        info@hamlet.ug
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center gap-3">
                    <i className="bi bi-geo-alt fs-5"></i>
                    <div className="d-flex flex-column">
                      <a href="https://goo.gl/maps/xyz" className="text-decoration-none fw-thin btn btn-sm text-start text-muted">
                        Hamlet Hub, Muyenga, Uganda
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
